import { createRouter, createWebHistory } from 'vue-router'
import SettingsView from '../views/SettingsView.vue'

const routes = [
  {
    path: "/",
    name: "orders2",
    component: () => import("../views/OrdersView.vue"),
  },

  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
  },

  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/OrdersView.vue"),
  },

  {
    path: "/previews",
    name: "subs",
    component: () => import("../views/PreviewView.vue"),
  },

  {
    path: "/downloads",
    name: "downloads",
    component: () => import("../views/DownloadView.vue"),
  },

  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/NotificationsView.vue"),
  },

  {
    path: "/transactions",
    name: "trans",
    component: () => import("../views/TransactionsView.vue"),
  },

  {
    path: "/explore",
    name: "explore",
    component: () => import("../views/ExploreView.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    };
  },
});

export default router
