import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAP_kbnYq_4OgSSnJO87q0lxaXWy7IkbjI",
  authDomain: "quepix-95062.firebaseapp.com",
  projectId: "quepix-95062",
  storageBucket: "quepix-95062.appspot.com",
  messagingSenderId: "762107722521",
  appId: "1:762107722521:web:97238f85ab73b3c9bf2388",
  measurementId: "G-5WH0K17WWR",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
