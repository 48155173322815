<template>
    
    <!-- BODY -->
    <div class="hp-main-layout-content">
        <div class="row mb-32 gy-32">

                    <!-- BANNER -->
                    <div class="col-12 col-xl-12">

                        <div class="card">
                            <div class="card-body">

                                <form @submit.prevent="settingsSubmit">

                                    <div class="col-12">
                                        <div class="row">

                                            <!-- left -->
                                            <div class="col-12 col-lg-7">
                                                <div class="row" style="padding-left: 20px; padding-right: 20px; padding-top: 20px;">

                                                    <div class="col-12">

                                                        <div style="position: relative; margin-bottom: 50px;">

                                                            <div class="avatar-item d-flex align-items-center justify-content-center avatar-lg bg-primary-4 hp-bg-dark-primary rounded-circle" >
                                                                <img src="@/assets/img/user.png" alt="" style="max-width: 90px; height: 90px; border-radius: 50%; " id="profilePre" >
                                                            </div>

                                                            <input type="file" class="form-control" accept="image/*" hidden id="userImg" @change="getProfileImageUrl">
                                                            <label for="userImg" class="form-label" style="cursor: pointer;">
                                                                <img src="@/assets/img/Group13.png" alt="" style="width:30px; position: absolute; bottom: 0px; left: 45px; cursor: pointer;">
                                                            </label>

                                                        </div>

                                                        

                                                    </div>

                                                    <div class="col-12 col-lg-6">

                                                        <div class="mb-24">
                                                            <label for="displayName" class="form-label"> <span class="text-danger me-4">*</span> First name</label>
                                                            <input type="text" class="form-control" required v-model="firstName">
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-12 col-lg-6">

                                                        <div class="mb-24">
                                                            <label for="displayName" class="form-label">
                                                                <span class="text-danger me-4">*</span>
                                                                Last name</label>
                                                            <input type="text" class="form-control" required v-model="lastName">
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-12 col-lg-6">

                                                        <div class="mb-24">
                                                            <label for="displayName" class="form-label">
                                                                <span class="text-danger me-4">*</span>
                                                                Phone number</label>
                                                            
                                                            <div class="input-group mb-16">
                                                                <span class="input-group-text" id="basic-addon1">+{{ initCountry.phoneCode }}</span>
                                                                <input type="tel" class="form-control" required v-model="phone">
                                                            </div>

                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-12 col-lg-6">

                                                        <div class="mb-24" style="position: relative;">
                                                            <label class="form-label"><span class="text-danger me-4">*</span>Country</label>
                                                            <img :src="initCountry.image" alt="" style="width: 30px; position: absolute; top: 38px; left: 20px;">
                                                            <select class="form-select" required style="padding-left: 70px; background-color: #ffffff; color: black; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#countryModal"  >
                                                                
                                                                <option value="ng" > {{ initCountry.name }} </option>
                                                                
                                                            </select>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-12 col-lg-12">

                                                        <div class="mb-24">
                                                            <label class="form-label"> <span class="text-danger me-4">*</span> Address</label>
                                                            <textarea name="orderDetails" id="orderDetails" class="form-control" rows="4"  required v-model="address"></textarea>
                                                        </div>
                                                        
                                                    </div>

                                                    <div class="col-12 col-lg-6 dd-none">

                                                        <div class="mb-24" v-if="agentName != ''">
                                                            <p class="hp-badge-text mxx1" style="margin-top: 30px; ">Agent</p>
                                                            <p style=" margin-top: -10px; font-size: 20px; font-weight: 400;">{{ agentName }}</p>
                                                        </div>
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- right -->
                                            <div class="col-12 col-lg-5">

                                                <div class="mb-24" style="padding-left: 20px; padding-right: 20px;">
                                                        <label for="exampleInputEmail1" class="form-label mxx1">Upload your brand logo(s)</label>
                                                        
                                                        
                                                        <br>

                                                        <div id="imgPack" >

                                                        </div>
                                                        
                                                        <!-- main DUPLI IMG -->
                                                        <img src="@/assets/img/imggs.png" alt="" style="width: 80px; border-radius: 5px; margin-bottom: 5px;  margin-right: 5px;" id="dudImg1">

                                                        <!-- dud imgs -->
                                                        <img src="@/assets/img/imggs.png" alt="" style="width: 80px; border-radius: 5px; margin-bottom: 5px;" id="dudImg2">

                                                        <input type="file" class="form-control" accept="image/*" multiple hidden id="iconImg2" @change="getImageUrl">
                                                        <label for="iconImg2" class="form-label" style="cursor: pointer; font-weight: 100; padding-left: 10px; " >
                                                            <i class="ri-2x ri-upload-2-line" style="font-style: normal; "></i>
                                                        </label>

                                                        <div id="emailHelp" class="form-text">Image size limit is 3MB.</div>
                                                        
                                                </div>

                                            
                                                <div class="mb-24 mm-none" v-if="agentName != ''">
                                                    <p class="hp-badge-text" style="margin-top: 50px; margin-left: 20px;">Agent</p>
                                                    <p style=" margin-top: -10px; font-size: 20px; font-weight: 400; margin-left: 20px;">{{ agentName }}</p>
                                                </div>

                                                <!-- BUTTON -->
                                                <div class="mb-24 mxx2" style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px;">
                                                    
                                                    <button class="btn btn-primary " style="  padding-top: 15px; padding-bottom: 15px; color: #ffffff;"  >Save</button>

                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>

                                </form>

                                
                            </div>
                        </div>

                    </div>
                    
        </div>
    </div>

    <!-- ******** MODALS ********* -->

    <div class="modal fade" id="countryModal" tabindex="-1" aria-labelledby="countryModal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-sm" >
                        <div class="modal-content">
                            <div class="modal-header py-16" style="margin-top: 10px;">
                                <h5 class="modal-title" id="createModalLabel" style="text-align: center; width: 100%;">Choose country</h5>
                                <button type="button" class="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                    <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px;"></i>
                                </button>
                            </div>

                            <div class="col-12" style="padding-left: 20px; padding-right: 20px;">
                                                    
                                    <input type="text" class="form-control" placeholder="Search..." style="margin-bottom: 20px; " v-model="search">  

                                </div>

                            <div class="divider my-0"></div>

                            <div class="modal-body py-18" >

                                

                                <!-- dupli -->
                                <div class="row" style="cursor: pointer;" data-bs-dismiss="modal" v-for="country in fCountryList" :key="country.id" @click="modalSelect(country.name, country.phoneCode, country.image)">

                                        
                                        <!-- LEFT -->
                                        <div class="col-4" >
                                            
                                            <img :src="country.image" alt="" style="width: 30px;">

                                        </div>

                                        <!-- RIGHT -->
                                        <div class="col-8">
                                        
                                            
                                            <p class="group-text" style="font-size: 14px; text-align: start; margin-left: -50px;"><span style="padding-right: 5px;">(+{{ country.phoneCode }})</span> {{ country.name }}</p>
                                            

                                        </div>

                                        <div class="col-12">
                                            <div class="divider my-4 mb-16"></div>
                                        </div>

                                        


                                </div>

                            </div>
                        </div>
                    </div>
    </div>

        <!-- SUCCESS -->
        <div style=" display: flex; justify-content: center; align-items: center; z-index: 99999999 " class="loadingModalBg" ref="successModal">
            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-6">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img src="@/assets/img/success.png" alt="Upgrade Account" class="hp-block mx-auto" style=" width: 200px; padding-top: 10px;">

                                <h5 class="mt-24 mb-16 me-4 text-success">Success!</h5>
                                <p class="hp-p1-body mb-0" >{{successText}}xxxxx</p>

                                <button class="btn btn-sm btn-primary" style="color: #ffffff; font-weight: 500; border-style: none; margin-top: 25px;" @click="closeS">Okay</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ERROR -->
        <div style=" display: flex; justify-content: center; align-items: center;  z-index: 99999999" class="loadingModalBg" ref="errorModal">

            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img src="@/assets/img/error.png" alt="Upgrade Account" class="hp-block mx-auto" style=" width: 150px; padding-top: 10px;">

                                <h5 class="mt-24 mb-16 me-4 text-danger" >Error!</h5>
                                <p class="hp-p1-body mb-0" >{{errorText}}</p>

                                <button class="btn btn-sm btn-primary" style="color: #ffffff; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeE">Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- LOADING -->
        <div style=" display: flex; justify-content: center; align-items: center;  z-index: 99999999" class="loadingModalBg" ref="loadingModal">
            <div class="col-12 col-xl-4" style="width: 300px;">
                <div class="card hp-card-4" >
                    <div class="card-body"  >
                        <div class="row"  >

                            <div class="col-12 text-center">
                                <img src="@/assets/img/loading.png"  class="hp-block mx-auto" style="width: 200px; padding-top: 30px;" >
                            </div>

                            <div class="col-12 text-center">

                                <h5 class="mt-24 mb-16 me-4 ">{{loadingText}}</h5>
                                <img src="@/assets/img/spinner.gif" alt="" style="width: 70px; margin-top: -10px">
                                                              
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>

<script>

    import countries from '@/assets/json/countries.json'
    import { doc, addDoc, collection, query, where, getDoc, getDocs, updateDoc, arrayUnion } from "firebase/firestore"; 
    import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
    import { db } from '@/firebase';
    export default {
        
        data(){
            return{
                
                loadingText: "",
                errorText: "",
                successText: "",
                countryList:[],
                search: "",
                initCountry: {
                    name: "Nigeria",
                    phoneCode: "234",
                    image: "https://flagcdn.com/256x192/ng.png"
                },

                dudSrc: "",

                firstName: "",
                lastName: "",
                phone: "",
                address: "",
                agentName: "",

                profilePicUrl: "",
                logoList:[],
                
            }
        },

        mounted(){

            this.getUserId();
        },

        computed:{

            fCountryList(){
                return this.countryList.filter( country => country.name.toLowerCase().startsWith(this.search.toLowerCase()) );
            }

        },

        methods: {

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            async getUserId(){

                //start loading
                this.loadingText = "Please wait...";
                this.$refs.loadingModal.classList.add("bg-active");

                let urlString = String(window.location.href);
                let url = new URL(urlString);
                let qid = url.searchParams.get("qid");
                // if(qid == null || sessionStorage.getItem("id") == "" || sessionStorage.getItem("id") == null){
                //     //back to login
                //     this.$refs.loadingModal.classList.remove("bg-active");                        
                //     window.location.replace("https://account.quepix.com/sign-in");
                //     return;
                // }

                //save id
                if(qid != null && qid != ""){
                    sessionStorage.setItem("id", String(qid));
                }
                

                //check valid and verified
                if(sessionStorage.getItem("id") != "" && sessionStorage.getItem("id") != null){

                    const docRef = doc(db, "Users", sessionStorage.getItem("id"));
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {

                        this.agentName = docSnap.data().agentName;

                        if(docSnap.data().verified == "no"){
                            //not verified
                            this.$refs.loadingModal.classList.remove("bg-active");                        
                            window.location.replace("https://account.kuepix.com/verify-email");
                            return; 
                        }
                    }
                    else{

                        //id not valid
                        sessionStorage.setItem("id", "");
                        this.$refs.loadingModal.classList.remove("bg-active");                        
                        window.location.replace("https://account.kuepix.com/sign-up");
                        return;

                    }

                }

                this.getCountry();

            },

            getCountry(){

                for(let i=0; i<240; i++){

                    this.countryList[i] = {
                        number: i,
                        id: String(countries[i].ISO2).toLowerCase(),
                        name: countries[i].CountryName,
                        phoneCode: String(countries[i].E164),
                        image: "https://flagcdn.com/256x192/"+String(countries[i].ISO2).toLowerCase()+".png",
                    }

                    if(countries[i].ISO2 == "AN"){
                        this.countryList[i] = {
                            number: i,
                            id: String(countries[i].ISO2).toLowerCase(),
                            name: countries[i].CountryName,
                            phoneCode: String(countries[i].E164),
                            image: "https://flagcdn.com/256x192/nl.png",
                        }
                    }

                }
                
                this.$refs.loadingModal.classList.remove("bg-active");

            },

            modalSelect(name, phoneCode, image){
                this.initCountry.name = name;
                this.initCountry.phoneCode = phoneCode;
                this.initCountry.image = image;
            },

            getImageUrl(){

                document.getElementById("imgPack").innerHTML= "";
                
                for(let i=0; i<document.getElementById("iconImg2").files.length; i++){
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(document.getElementById("iconImg2").files[i]);
                    fileReader.addEventListener("load", function () {
                        document.getElementById("imgPack").innerHTML += '<img src="' + this.result + '" style="  border-radius: 5px; margin-bottom: 5px;  margin-right: 5px; border-style: solid; border-width: 1px; border-color:#B2BEC3" class="avatar-item avatar-xl bg-primary-4 hp-bg-dark-primary " />';

                        document.getElementById("dudImg1").style.display = 'none';
                        document.getElementById("dudImg2").style.display = 'none';
                    });    
                }
                
            },

            getProfileImageUrl(){

                const fileReader = new FileReader();
                fileReader.readAsDataURL(document.getElementById("userImg").files[0]);
                fileReader.addEventListener("load", function () {
                    document.getElementById("profilePre").src = this.result;
                }); 

            },

            async updateProfilePic(url){
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    
                    profilePic: String(url),
                    
                });
            },

            async updateBrandLogo(url){
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                                    
                    brandLogoList: arrayUnion(String(url)), 
                                    
                });
            },

            async settingsSubmit(){

                let profilePicInput = document.getElementById("userImg");
                let brandLogoInput = document.getElementById("iconImg2");

                //start loading
                this.loadingText = "Saving user info...";
                this.$refs.loadingModal.classList.add("bg-active");

                if(profilePicInput.files.length < 1){
                    this.errorText = "Please add an image for your profile";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //check phone
                if(String(this.phone).length < 9){
                    this.errorText = "Invalid phone number";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //check profile pic size
                if(profilePicInput.files[0].size > 3000000){
                    this.errorText = profilePicInput.files[0].name+" is too large. Maximum size for image is 3MB";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //upload data(update)
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: this.phone,
                    address: this.address,
                    countryName: this.initCountry.name,
                    countryPhoneCode: this.initCountry.phoneCode,
                    countryImage: this.initCountry.image,
                    previews: 0,
                    
                });

                //upload profile image and save url
                const storage = getStorage();

                // Create the file metadata
                /** @type {any} */

                const metadata = {
                    contentType: 'image/*'
                };

                const storageRef = ref(storage, 'UserImages/ProfilePics/' + profilePicInput.files[0].name);
                const uploadTask = uploadBytesResumable(storageRef, profilePicInput.files[0], metadata);

                // Listen for state changes, errors, and completion of the upload.
                uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                    }
                },
                (error) => {
                    switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        this.errorText = "Unknown error occured";
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.errorModal.classList.add("bg-active");
                        console.log(error.serverResponse);
                        return;
                        break;
                    }
                }, 
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                
                        this.updateProfilePic(downloadURL);
                        
                    });
                }); 



                //*****upload brand logos and save url if not empty*****
                if(brandLogoInput.files.length > 0){

                    //check brand logo size and length
                    if(brandLogoInput.files.length > 5){
                        this.errorText = "Too many brand logos. Maximum is five (5) images";
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.errorModal.classList.add("bg-active");
                        return;
                    }

                    for(let i=0; i<brandLogoInput.files.length ; i++){

                        if(brandLogoInput.files[i].size > 5000000){
                            this.errorText = brandLogoInput.files[i].name+" is too large. Maximum size for individual images is 5MB";
                            this.$refs.loadingModal.classList.remove("bg-active");
                            this.$refs.errorModal.classList.add("bg-active");
                            break;
                            return;
                        }

                        //upload brand logos
                        const storage = getStorage();
                        const storageRef = ref(storage, 'UserImages/BrandLogos/' + brandLogoInput.files[i].name);
                        const uploadTask = uploadBytesResumable(storageRef, brandLogoInput.files[i], metadata);

                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on('state_changed',
                        (snapshot) => {
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            //console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                            case 'paused':
                                //console.log('Upload is paused');
                                break;
                            case 'running':
                                //console.log('Upload is running');
                                break;
                            }
                        },
                        (error) => {
                            switch (error.code) {
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;
                            case 'storage/unknown':
                                // Unknown error occurred, inspect error.serverResponse
                                this.errorText = "Unknown error occured";
                                this.$refs.loadingModal.classList.remove("bg-active");
                                this.$refs.errorModal.classList.add("bg-active");
                                console.log(error.serverResponse);
                                return;
                                break;
                            }
                        }, 
                        () => {
                            // Upload completed successfully, now we can get the download URL
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        
                                this.updateBrandLogo(downloadURL);
                                
                            });
                        }); 


                    }

                    //settings complete - go to orders
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$router.push({name: 'orders'});

                }
                else{
                    //settings complete - go to orders
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$router.push({name: 'orders'});
                }
                
                


            }

        }

    }
</script>