<template>
  <SettingsBody/>
</template>

<script>
// @ is an alias to /src
import SettingsBody from '@/components/SettingsBody.vue'

export default {
  name: 'SettingsView',
  components: {
    SettingsBody
  }
}
</script>
