<template>
  
  <main class="hp-bg-color-dark-90 d-flex min-vh-100" >

        <!-- DESKTOP SIDEBAR -->
        <div class="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-end border-black-40 hp-border-color-dark-80">
            <div class="hp-sidebar-container" >
                <div class="hp-sidebar-header-menu" >
                    <div class="row justify-content-between align-items-end mx-0" >
                        <!-- <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-visible">
                            <div class="hp-cursor-pointer">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                    <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                </svg>
                            </div>
                        </div> -->

                        <div class="w-auto px-0" >
                            <div class="hp-header-logo d-flex align-items-center">
                                <router-link :to="{name: 'orders'}" class="position-relative" >
                                    
                                    <!-- <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo"  >
                                    <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/Logos-07W.png" alt="logo" >
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo" >
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/Logos-07W.png" alt="logo">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/Logos-07W.png" alt="logo"> -->

                                    <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo"  >
                                    <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/colorLogo.png" alt="logo" style="margin-top: 30px;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo" >
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/colorLogo.png" alt="logo" style="margin-top: 30px;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/colorLogo.png" alt="logo" style="margin-top: 30px;">

                                    

                                </router-link>

                                
                            </div>
                        </div>

                        <!-- <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden">
                            <div class="hp-cursor-pointer mb-4">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                    <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                </svg>
                            </div>
                        </div> -->
                    </div>

                     <ul>    
                       
                        <li>
                            <div class="menu-title"></div>
                            <ul>

                                <li>
                                    <router-link  :to="{name: 'orders'}" id="ordersN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/orders.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                <img src="@/assets/img/ordersW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">  
                                            </span>

                                            <span>Orders</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'subs'}" id="subsN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span style="margin-top: 10px;">
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/preview.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                <img src="@/assets/img/previewW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                            </span>

                                            <span>Previews</span>

                                            <span v-if="previews > 0" style="margin-left: 10px" class="badge hp-text-color-black-100 hp-bg-secondary-3 rounded-pill px-8 border-0 badge-none">{{ previews }}</span>
                                            
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'downloads'}" id="downloadsN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span style="margin-top: 15px;">
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/downloads.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                <img src="@/assets/img/downloadsW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                            </span>

                                            <span>Downloads</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'notifications'}" id="notificationsN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span style="margin-top: 15px;">
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/bell.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                <img src="@/assets/img/bellW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto"> 
                                            </span>

                                            <span>Notifications</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'trans'}" id="transN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span style="margin-top: 15px;">
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/trans.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                <img src="@/assets/img/transW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                            </span>

                                            <span>Transactions</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'explore'}" id="exploreN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span style="margin-top: 15px;">
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/explore.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                <img src="@/assets/img/exploreW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                            </span>

                                            <span>Explore</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <a href="https://account.kuepix.com/sign-in">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span style="margin-top: 15px;">
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/exit.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                <img src="@/assets/img/exitW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                            </span>

                                            <span>Sign out</span>
                                        </span>
                                    </a>
                                </li>

                            </ul>
                        </li>
   
                    </ul>

                </div>

                <div class="row justify-content-between align-items-center hp-sidebar-footer mx-0 hp-bg-color-dark-90">
                    <div class="divider border-black-40 hp-border-color-dark-70 hp-sidebar-hidden mt-0 px-0"></div>

                    <div class="col">
                        <div class="row align-items-center">
                            <div class="w-auto px-0">
                                <div class="avatar-item bg-primary-4 d-flex align-items-center justify-content-center rounded-circle" style="width: 48px; height: 48px;">
                                    <img :src="profilePic"  class="hp-img-cover">
                                </div>
                            </div>

                            <div class="w-auto ms-8 px-0 hp-sidebar-hidden mt-4">
                                <span class="d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body lh-1" style="margin-top: 15px;">{{ firstName }} {{ lastName }}</span>
                                <router-link :to="{name: 'profile'}" class="hp-badge-text fw-normal hp-text-color-dark-30">View profile</router-link>

                                

                            </div>
                        </div>
                    </div>

                    <!-- <div class="col hp-flex-none w-auto px-0 hp-sidebar-hidden">
                        <router-link :to="{name: 'settings'}">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="remix-icon hp-text-color-black-100 hp-text-color-dark-0" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                </g>
                            </svg>
                        </router-link>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="hp-main-layout">

            <!-- HEADER -->
            <header>
                <div class="row w-100 m-0">
                    <div class="col px-0">
                        <div class="row w-100 align-items-center justify-content-between position-relative">
                            <div class="col w-auto hp-flex-none hp-mobile-sidebar-button me-24 px-0"  data-bs-toggle="offcanvas" data-bs-target="#mobileMenu" aria-controls="mobileMenu">
                                <button type="button" class="btn btn-text btn-icon-only">
                                    <i class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1" style="font-size: 24px;"></i>
                                </button>
                            </div>

                            <div class="hp-horizontal-logo-menu d-flex align-items-center w-auto">
                                <div class="col hp-flex-none w-auto hp-horizontal-block">
                                    <div class="hp-header-logo d-flex align-items-center">
                                        <a :to="{name: 'orders'}" class="position-relative">
                                            <div class="hp-header-logo-icon position-absolute bg-black-20 hp-bg-dark-90 rounded-circle border border-black-0 hp-border-color-dark-90 d-flex align-items-center justify-content-center" style="width: 18px; height: 18px; top: -5px;">
                                                <svg class="hp-fill-color-dark-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.709473 0L1.67247 10.8L5.99397 12L10.3267 10.7985L11.2912 0H0.710223H0.709473ZM9.19497 3.5325H4.12647L4.24722 4.88925H9.07497L8.71122 8.95575L5.99397 9.70875L3.28047 8.95575L3.09522 6.87525H4.42497L4.51947 7.93275L5.99472 8.33025L5.99772 8.3295L7.47372 7.93125L7.62672 6.21375H3.03597L2.67897 2.208H9.31422L9.19572 3.5325H9.19497Z" fill="#2D3436" />
                                                </svg>
                                            </div>

                                            <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/Logos-07W.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/Logos-07W.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/Logos-07W.png" alt="logo">
                                        </a>

                                        
                                    </div>
                                </div>

                               
                            </div>

                            <div class="col hp-flex-none w-auto pe-0">
                                <div class="row align-items-center justify-content-end">

                                    <!-- TRANSLATOR -->
                                    <div class="hover-dropdown-fade w-auto px-0 d-flex align-items-center position-relative" style="margin-top: 3px; cursor: pointer; ">
                                        
                                        <!-- GTRANSLATE ELEMENT -->
                                        <div id='Anutrickz_translateBTN' @click="openTran">
                        
                                            <img src="./assets/img/lang.png" alt="" style="width: 28px; margin-left: 10px;" class="hp-dark-none mx-auto mm-none">
                                            <img src="./assets/img/langW.png" alt="" style="width: 28px; margin-left: 10px;" class="hp-dark-block mx-auto mm-none">

                                            <img src="./assets/img/lang.png" alt="" style="width: 35px; margin-left: 10px;" class="hp-dark-none mx-auto dd-none">
                                            <img src="./assets/img/langW.png" alt="" style="width: 35px; margin-left: 10px;" class="hp-dark-block mx-auto dd-none">

                                            <!-- <i class="hp-text-color-dark-0 ri-2x ri-translate-line"></i> -->

                                            <div class='hide' id='ATLang_list'>
                                                <ul>
                                                    <li class='language-list' data-google-lang='en'>English</li>
                                                    <li class='language-list' data-google-lang='ig'>Igbo</li>
                                                    <li class='language-list' data-google-lang='yo'>Yoruba</li>
                                                    <li class='language-list' data-google-lang='ha'>Hausa</li>
                                                    <li class='language-list' data-google-lang='fr'>French</li>
                                                    <li class='language-list' data-google-lang='de'>German</li>
                                                    <li class='language-list' data-google-lang='es'>Spanish</li>
                                                    <li class='language-list' data-google-lang='nl'>Dutch</li>
                                                    <li class='language-list' data-google-lang='fi'>Finnish</li>
                                                    <li class='language-list' data-google-lang='it'>Italian</li>
                                                    <li class='language-list' data-google-lang='ar'>Arabic</li>
                                                    <li class='language-list' data-google-lang='zh-TW'>Chinese</li>
                                                    <li class='language-list' data-google-lang='ja'>Japanese</li>
                                                </ul>
                                            </div>
                                        
                                        </div>

                                    </div>

                                    

                                    <!-- notification -->
                                    <div class="me-2 hp-basket-dropdown-button w-auto px-0 position-relative " style="width: 100%; cursor: pointer; margin-left: 10px; margin-right: 10px; " @click="openNoti()">
                                        
                                        <!-- <i class="hp-text-color-dark-0 ri-2x ri-notification-3-line"></i> -->
                                    
                                        <img src="./assets/img/bell.png" alt="" style="width: 30px; margin-left: 15px; margin-right: 15px;" class="hp-dark-none mx-auto dd-none">
                                        <img src="./assets/img/bellW.png" alt="" style="width: 30px; margin-left: 15px; margin-right: 15px;" class="hp-dark-block mx-auto dd-none">

                                        <img src="./assets/img/bell.png" alt="" style="width: 23px; margin-left: 15px; margin-right: 15px;" class="hp-dark-none mx-auto mm-none">
                                        <img src="./assets/img/bellW.png" alt="" style="width: 23px; margin-left: 15px; margin-right: 15px;" class="hp-dark-block mx-auto mm-none">

                                        <span v-if="nCount > 0" class="position-absolute top-0 start-100 translate-middle px-6 border border-light rounded-circle" style="font-size: 12px; background-color: #9747ff; color: #ffffff; " > {{ nCount }}
                                            <span class="visually-hidden">New alerts</span>
                                        </span>
                                        
                                    </div>


                                    <!-- USER PROFILE -->
                                    <!-- <div class="me-2 hp-basket-dropdown-button w-auto px-0 position-relative mm-none" style="width: 100%; cursor: pointer;">
                                        
                                        <img src="./assets/img/profile.png" alt="" style="width: 30px;" class="hp-dark-none mx-auto dd-none">
                                        <img src="./assets/img/profileW.png" alt="" style="width: 30px;" class="hp-dark-block mx-auto dd-none">

                                        <img src="./assets/img/profile.png" alt="" style="width: 23px;" class="hp-dark-none mx-auto mm-none">
                                        <img src="./assets/img/profileW.png" alt="" style="width: 23px;" class="hp-dark-block mx-auto mm-none">

                                        
                                    </div> -->

                                    <!-- MODE -->
                                    <div class="hover-dropdown-fade w-auto px-0 d-flex align-items-center position-relative" @click="setTheme()" style="cursor: pointer;">
                                        
                                        <img src="./assets/img/moon.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                        <img src="./assets/img/moonW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">

                                        
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </header>

            <!-- MOBILE SIDEBAR -->
            <div class="offcanvas offcanvas-start hp-mobile-sidebar bg-black-20 hp-bg-dark-90" tabindex="-1" id="mobileMenu" aria-labelledby="mobileMenuLabel" style="width: 256px;">
                <div class="offcanvas-header justify-content-between align-items-center ms-16 me-8 mt-16 p-0">
                    <div class="w-auto px-0">
                        <div class="hp-header-logo d-flex align-items-center">
                            <router-link :to="{name: 'orders'}" class="position-relative">
                                <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/colorLogo.svg" alt="logo" style="margin-top: 30px;">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/colorLogo.svg" alt="logo" style="margin-top: 30px;">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/colorLogo.svg" alt="logo" style="margin-top: 30px;">

                                
                            </router-link>

                           
                        </div>
                    </div>

                    <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden" data-bs-dismiss="offcanvas" aria-label="Close">
                        <button type="button" class="btn btn-text btn-icon-only bg-transparent">
                            <i class="ri-close-fill lh-1 hp-text-color-black-80" style="font-size: 24px;"></i>
                        </button>
                    </div>
                </div>

                <div class="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-end border-black-40 hp-border-color-dark-80">
                    <div class="hp-sidebar-container">
                        <div class="hp-sidebar-header-menu">
                            <div class="row justify-content-between align-items-end mx-0">
                                <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-visible">
                                    <div class="hp-cursor-pointer">
                                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                            <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                        </svg>
                                    </div>
                                </div>

                                <div class="w-auto px-0">
                                    <div class="hp-header-logo d-flex align-items-center">
                                        <router-link :to="{name: 'orders'}" class="position-relative">
                                            

                                            <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/Logos-07.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/Logos-07.png" alt="logo">
                                        </router-link>

                                        
                                    </div>
                                </div>

                                <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden">
                                    <div class="hp-cursor-pointer mb-4">
                                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                            <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <ul>    
                       
                                <li>
                                    <div class="menu-title"></div>
                                    <ul>

                                        <li>
                                            <router-link :to="{name: 'orders'}" id="ordersN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/orders.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                        <img src="@/assets/img/ordersW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">  
                                                    </span>

                                                    <span>Orders</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li>
                                            <router-link :to="{name: 'subs'}" id="subsN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span style="margin-top: 10px;">
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/preview.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                        <img src="@/assets/img/previewW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                                    </span>

                                                    <span>Previews</span>

                                                    <span v-if="previews > 0" style="margin-left: 10px" class="badge hp-text-color-black-100 hp-bg-secondary-3 rounded-pill px-8 border-0 badge-none">{{ previews }}</span>

                                                </span>
                                            </router-link>
                                        </li>

                                        <li>
                                            <router-link :to="{name: 'downloads'}" id="downloadsN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span style="margin-top: 15px;">
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/downloads.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                        <img src="@/assets/img/downloadsW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                                    </span>

                                                    <span>Downloads</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li>
                                            <router-link :to="{name: 'notifications'}" id="notificationsN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span style="margin-top: 15px;">
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/bell.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                        <img src="@/assets/img/bellW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto"> 
                                                    </span>

                                                    <span>Notifications</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li>
                                            <router-link :to="{name: 'trans'}" id="transN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span style="margin-top: 15px;">
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/trans.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                        <img src="@/assets/img/transW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                                    </span>

                                                    <span>Transactions</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li>
                                            <router-link :to="{name: 'explore'}" id="exploreN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span style="margin-top: 15px;">
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/explore.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                        <img src="@/assets/img/exploreW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                                    </span>

                                                    <span>Explore</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li>
                                            <a href="https://account.kuepix.com/sign-in">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span style="margin-top: 15px;">
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/exit.png" alt="" style="width: 25px;" class="hp-dark-none mx-auto">
                                                        <img src="@/assets/img/exitW.png" alt="" style="width: 25px;" class="hp-dark-block mx-auto">
                                                    </span>

                                                    <span>Sign out</span>
                                                </span>
                                            </a>
                                        </li>

                                    </ul>
                                    
                                </li>
        
                            </ul>
                          
                            
                        </div>

                        <div class="row justify-content-between align-items-center hp-sidebar-footer mx-0 hp-bg-color-dark-90">
                            <div class="divider border-black-40 hp-border-color-dark-70 hp-sidebar-hidden mt-0 px-0"></div>

                            <div class="col">
                                <div class="row align-items-center">
                                    <div class="w-auto px-0">
                                        <div class="avatar-item bg-primary-4 d-flex align-items-center justify-content-center rounded-circle" style="width: 48px; height: 48px;">
                                            <img :src="profilePic"  class="hp-img-cover">
                                        </div>
                                    </div>

                                    <div class="w-auto ms-8 px-0 hp-sidebar-hidden mt-4">
                                        <span class="d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body lh-1">{{ firstName }} {{ lastName }}</span>
                                        <router-link :to="{name: 'profile'}" class="hp-badge-text fw-normal hp-text-color-dark-30">View Profile</router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="col hp-flex-none w-auto px-0 hp-sidebar-hidden">
                                <router-link :to="{name: 'profile'}">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="remix-icon hp-text-color-black-100 hp-text-color-dark-0" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                        </g>
                                    </svg>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- VIEW -->
            <router-view/>
            
        </div>

    </main>
  
</template>

<style>

    @import url(./assets/css/swiper-bundle.min.css);
    @import url(./assets/css/index.min.css);
    @import url(./assets/css/index.min2.css);
    @import url(./assets/css/bootstrap.css);
    @import url(./assets/css/colors.css);
    @import url(./assets/css/base.css);
    @import url(./assets/css/colors-dark.css);
    @import url(./assets/css/theme-dark.css);
    @import url(./assets/css/custom-rtl.css);
    @import url(./assets/css/sider.css);
    @import url(./assets/css/header.css);
    @import url(./assets/css/page-content.css);
    @import url(./assets/css/components.css);
    @import url(./assets/css/style.css);
    @import url(./assets/css/typography.css);
    @import url(./assets/css/gTranslate.css);


</style>

<script>

    import { doc, collection, query, getDoc, getDocs, } from "firebase/firestore"; 
    
    import { db } from '@/firebase';

    export default {
        
        mounted(){

             document.addEventListener('contextmenu', function(e) {
                e.preventDefault(); 
            });

            window.addEventListener("click", function (e) {
            if (document.getElementById("Anutrickz_translateBTN").contains(e.target)) {
                //inside box
                return;
            } 
            
            //outside box
            document.getElementById("ATLang_list").classList.remove("show");
            document.getElementById("ATLang_list").classList.add("hide");

            });



            this.getNoti();

        },

        data(){
            return{
                nCount: 0,
                email: "",
                firstName: "",
                lastName: "",
                profilePic: "",
                previews: 0
            }
        },

        methods:{

            openTran(){
                document.getElementById("ATLang_list").classList.remove("hide");
                document.getElementById("ATLang_list").classList.add("show");
            },

            closeTran(){
                document.getElementById("ATLang_list").classList.remove("show");
                document.getElementById("ATLang_list").classList.add("hide");
            },

            openNoti(){
                this.$router.push({name: 'notifications'});
            },

            async getNoti(){

                const docRef = doc(db, "Users", sessionStorage.getItem("id"));
                const docSnap = await getDoc(docRef);

                this.nCount = docSnap.data().notiCount;
                this.email = docSnap.data().email;
                this.firstName = docSnap.data().firstName;
                this.lastName = docSnap.data().lastName;
                this.profilePic = docSnap.data().profilePic;
                this.previews = docSnap.data().previews;

            },

            setTheme(){
                document.body.classList.toggle("dark");
            }

        }

    }
</script>


